.pets {
    &-color {
        border: 1px solid $color-gray-light;
        border-radius: 10px;
        margin-bottom: 30px;
        @include view-at(sp) {
          margin-bottom: 20px;
        }
    
        &-ttl {
          font-size: 14px;
          text-align: center;
          padding: 10px;
          border-bottom: 1px solid $color-gray-light;
        }
        &-select {
          position: relative;
          padding: 10px;
    
          &-img {
            width: 15%;
            padding-right: 10px;
          }
          &-text {
            width: 85%;
            font-size: 14px;
          }
        }
    
        .display_selected {
          &-wrap {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            padding: 0 10px;
            position: relative;
    
            &::after {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 15px;
              content: '▼';
              display: inline-block;
              font-size: 8px;
            }
          }
          &Item {
            width: 85%;
            &-img {
              width: 15%;
              padding-right: 10px;
            }
          }
        }
        .select_box {
            width: 100%;
            border: 1px solid $color-gray-light;
            filter: drop-shadow(0 0 5px $color-gray-light);
            position: absolute;
            left: 0;
            top: 95%;
            z-index: +1;
            background: white;
    
            li {
              cursor: default;
              box-sizing: border-box;
              padding: 10px 20px;
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              font-size: 14px;
              width: 100%;
            }
        }
      }
}