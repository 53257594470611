
body {
  font-family: Century Gothic;
  font-weight: 300;
  color: $color-black;
  position: relative;
  letter-spacing: 0.1em;
}

a {
  color: $color-black;
  text-decoration: none;
  &:hover {
    opacity: 0.6;
  }
}

p {
  font-size: nth($font-size, 4);
  line-height: nth($line-height, 4);
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  @include view-at(sp) {
    font-size: nth($font-size-sp, 4);
    line-height: nth($line-height-sp, 4);
    margin-bottom: 10px;
  }
}

img {
  width: auto;
  height: auto;
  max-width: 100%;
}

.main {
  padding-top: 70px;
  @include view-at(sp) {
    padding-top: 50px;
  }
}

.wp-pagenavi {
  text-align: center;
}

.wp-pagenavi .page {
  margin: 0 10px;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;

  &-item {
    padding: 5px;
  }
}

.gallery-columns-1 .gallery-item {
  width: 100%;
}

.gallery-columns-2 .gallery-item {
  width: 50%;
}

.gallery-columns-3 .gallery-item {
  width: 33.3333%;
}

.gallery-columns-4 .gallery-item {
  width: 25%;

  @include view-at(sp) {
    width: 50%;
  }
}

.gallery-columns-5 .gallery-item {
  width: 20%;

  @include view-at(sp) {
    width: 50%;
  }
}


.page-nav {
  padding-top: 40px;
  ul {
    display: flex;
    justify-content: center;
    @include view-at(sp) {
      flex-wrap: wrap;
    }

    li {
      padding: 20px 40px;
      @include view-at(sp) {
        padding: 10px;
        width: 50%;
      }
      
      a {
        font-size: 16px;
        position: relative;
        padding-bottom: 10px;
        @include view-at(sp) {
          font-size: 12px;
          display: block;
        }
        span {
          padding-right: 20px;
          font-size: 25px;
          font-weight: bold;
          @include view-at(sp) {
            font-size: 20px;
            padding-right: 10px;
          }
        }
        &::after {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 5px;
          content: '';
          background-image: -webkit-repeating-linear-gradient(135deg, $color-gray, $color-gray 1px, transparent 2px, transparent 5px);
          background-image: repeating-linear-gradient(-45deg, $color-gray, $color-gray 1px, transparent 2px, transparent 5px);
          background-size: 7px 7px;
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
        }
      }
    }
  }
}

.shadow {
  filter: drop-shadow(2px 2px 4px #c1c1c1);
}