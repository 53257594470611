/* --------------------------
ボタン
-------------------------- */

.c-button {
  display: inline-block;
  width: auto;
  padding: 15px 55px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  color: $color-black;
  background-color: $color-white;
  border: 1px solid $color-gray;
  position: relative;
  @include view-at(sp) {
    font-size: 13px;
  }

  &:hover {
    opacity: 0.7;
    text-decoration: none;
  }

  &.black {
    background: #000;
    color: white;
  }

  &--arrow {
    &::after {
      position: absolute;
      top: 50%;
      right: 18px;
      margin-top: -8px;
      content: '>';
      display: inline-block;
    }

    &-left {
      &::after {
        position: absolute;
        top: 50%;
        left: 18px;
        margin-top: -8px;
        content: '<';
        display: inline-block;
      }
    }
  }

  &--primary {
    background-color: $color-red;
    border: 0;
    color: $color-white;
    padding: 25px 70px;

    @include view-at(sp) {
      padding: 15px;
      width: 100%;
    }
  }

  &--disabled {
    pointer-events: none;
    background-color: $color-gray-light;
    border-color: $color-gray-light;
    color: $color-white;
  }

  &--large {
    padding: 20px;
    font-size: nth($font-size, 3);
    min-width: 200px;
  }

  &--small {
    padding: 10px;
    font-size: nth($list: $font-size, $n: 5)
  }
}
