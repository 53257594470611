/* --------------------------
見出し
-------------------------- */



.c-h1 {
  margin-top: 190px;
  color: $color-black;
  text-align: center;
  font-weight: 400;
  letter-spacing: 5px;
  padding: 25px;
  font-size: nth($font-size, 1);
  line-height: nth($line-height, 1);
  @include view-at(sp) {
    font-size: nth($font-size-sp, 1);
    line-height: nth($line-height-sp, 1);
    padding: 15px;
    margin-top: 110px;
  }

  small {
    display: block;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 3px;
  }
}

.c-h2 {
  margin: 0 auto 40px;
  font-weight: $bold;
  font-size: 20px;
  line-height: 1.3;
  letter-spacing: 0.15em;
  text-indent: 0.2em;
  @include view-at(sp) {
    margin: 0 auto 20px;
    font-size: 18px;
    line-height: 1.5;
  }

  small {
    font-family: serif;
    font-weight: 600;
  }
}

.c-h3 {
  font-size: nth($font-size, 2);
  line-height: 1.8em;
  letter-spacing: 0.08em;
  font-weight: $normal;
  margin-bottom: 20px;
  font-family: serif;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 2);
    line-height: nth($line-height-sp, 2);
  }
}

.c-h4 {
  font-size: nth($font-size, 3);
  line-height: nth($line-height, 3);
  letter-spacing: 0.08em;
  font-weight: $bold;
  margin-bottom: 20px;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 3);
    line-height: nth($line-height-sp, 3);
  }
}

.c-h5 {
  font-size: nth($font-size, 4);
  line-height: nth($line-height, 4);
  letter-spacing: 0.08em;
  font-weight: $bold;
  margin-bottom: 1em;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 4);
    line-height: nth($line-height-sp, 4);
  }
}

.c-h6 {
  font-size: nth($font-size, 4);
  line-height: nth($line-height, 4);
  letter-spacing: 0.08em;
  font-weight: $bold;
  margin-bottom: 1em;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 6);
    line-height: nth($line-height-sp, 6);
  }
}

.c-h--center-line {
  @include center-line();
  margin-bottom: 5px;
}


// 見出しデザイン

.h-border {
  position: relative;
  padding: 1.5rem 1rem;
  margin-bottom: 40px;
  letter-spacing: 0.3em;
}

.h-border:after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  content: '';
  background-image: -webkit-repeating-linear-gradient(135deg, $color-gray, $color-gray 1px, transparent 2px, transparent 5px);
  background-image: repeating-linear-gradient(-45deg, $color-gray, $color-gray 1px, transparent 2px, transparent 5px);
  background-size: 7px 7px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.h-border span{
  padding-right: 20px;
  font-size: 40px;
  font-weight: bold;
}

.h-border-icon {
  position: relative;
  padding: 1.5rem;
  text-align: center;
  border: 2px solid #000;
  background: #fff;
}

.h-border-icon:before,
.h-border-icon:after {
  position: absolute;
  content: '';
}

.h-border-icon:before {
  top: -40px;
  left: calc(50% - 40px);
  width: 80px;
  height: 80px;
  border: 2px solid #000;
  border-radius: 50%;
  background: #fff;
}

.h-border-icon:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
}

.h-border-icon i {
  font-size: 30px;
  font-size: 3rem;
  line-height: 60px;
  position: absolute;
  z-index: 1;
  top: -40px;
  left: calc(50% - 40px);
  width: 80px;
  height: 60px;
  text-align: center;
}

.h-border-icon span {
  position: relative;
  z-index: 1;
}


.h-no {
  position: relative;
  overflow: hidden;
  padding: 1.5rem 2rem 1.5rem 130px;
  border-top: 3px solid #000;
  letter-spacing: 0.4em;
  margin-bottom: 40px;
}

.h-no:before {
  position: absolute;
  top: -150%;
  left: -100px;
  width: 200px;
  height: 300%;
  content: '';
  -webkit-transform: rotate(25deg);
  transform: rotate(25deg);
  background: #000;
}

.h-no span {
  font-size: 40px;
  font-size: 4rem;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  display: block;
  padding-top: 3px;
  padding-left: 16px;
  color: #fff;
  letter-spacing: 0em;
}


.h-ribon {
  position: relative;
   margin: 30px;
   text-align: center;
 }
 
 .h-ribon:before,
 .h-ribon:after {
   position: absolute;
   z-index: 0;
   bottom: -10px;
   display: block;
   content: '';
   border: 1em solid #d90606;
 }
 
 .h-ribon:before {
   left: -30px;
   border-left-width: 15px;
   border-left-color: transparent;
 }
 
 .h-ribon:after {
   right: -30px;
   border-right-width: 15px;
   border-right-color: transparent;
 }
 
 .h-ribon span {
   position: relative;
   z-index: 1;
   display: block;
   padding: 1rem 2rem;
   color: #fff;
   background: #fa4141;
 }
 
 .h-ribon span:before,
 .h-ribon span:after {
   position: absolute;
   bottom: -10px;
   display: block;
   width: 10px;
   height: 10px;
   content: '';
   border-style: solid;
   border-color: #b70505 transparent transparent transparent;
 }
 
 .h-ribon span:before {
   left: 0;
   border-width: 10px 0 0 10px;
 }
 
 .h-ribon span:after {
   right: 0;
   border-width: 10px 10px 0 0;
 }