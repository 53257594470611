
.p-exnav {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: 13px;
  background: white;
  border-top: 1px solid $color-gray-snow;
  display: none;
  z-index: 9999;
  padding: 10px 0;
  @include view-at(sp) {
    display: flex;
    flex-wrap: wrap;
  }

  li {
    width: calc(100%/5);

    a {
      text-decoration: none;
      text-align: center;
      display: block;
      font-size: 10px;
      letter-spacing: 0;
      i {
        display: block;
        font-size: 25px;
        margin-bottom: 5px;
        color: $color-gray-dark;
      }
    }
  }
}
