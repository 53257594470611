@charset "UTF-8";


.slick-dots {
  position: absolute;
  bottom: -40px;
  left: 0;
  width: 100%;
  font-size: 0;
  text-align: center;
  list-style: none;
}
.slick-dots li {
  display: inline-block;
  margin: 0 8px;
}
.slick-dots li button {
  display: block;
  font-size: 0;
  line-height: 0;
  width: 12px;
  height: 12px;
  background: #aaa;
  border: 0;
  border-radius: 50%;
  color: transparent;
  cursor: pointer;
}
.slick-dots .slick-active button,
.slick-dots li button:hover {
  background: #222;
}
@include view-at(pc) {
  .slider-full {
    margin: 40px auto;
  }
  .slider-wrap01 {
    max-width: 1400px;
    margin: 40px auto;
  }
  .slick-slide img {
    width: 100%;
    height: auto;
  }
  .slick-arrow {
    position: absolute;
    top: 50%;
    z-index: 100;
    padding: 4px 8px;
    background: #000;
    border: none;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #fff;
    font-size: 16px;
    font-weight: 300;
    cursor: pointer;
  }
  .slick-arrow:hover {
    color: #fff;
    opacity: .6;
  }
  .slick-prev {
    left: 20px;
  }
  .slick-next {
    right: 20px;
  }

  #slick04 .slick-slide {
    transition: .3s ease;
    transform: scale(.85);
    transform-origin: 110% 50%;
    filter: blur(7px);
  }
  #slick04 .slick-current {
    position: relative;
    z-index: 2;
    transform: scale(1);
    transform-origin: 50% 50%;
    filter: blur(0);
  }
  #slick04 .slick-current + .slick-slide {
    transform-origin: -10% 50%;
  }
}


.top {
  &-mv {
    margin-bottom: 130px;
    margin-top: 100px;
    @include view-at(sp) {
      margin-top: 0;
    }
    &-slid {
      background: white;
      position: relative;

      a {
        color: white;
      }
      &-text {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: 20px;
        align-items: center;
        padding-left: 20px;
        background: $color-main;
        color: white;
        letter-spacing: 0.1em;
        small {
          font-family: serif;
          padding-left: 20px;
          border-left: 1px solid;
          margin-left: 20px;
        }
        @include view-at(sp) {
          font-size: 16px;
          display: block;
          padding: 20px;
          position: static;
          text-align: center;

          small {
            display: block;
            margin-top: 5px;
            font-family: serif;
            border: none;
            padding: 10px 0 0;
            margin: 0;
          }
        }

        &-link {
          font-size: 12px;
          text-decoration: none;
          padding: 20px 40px;
          display: block;
          background: $color-main;
          color:white;
          letter-spacing: 0.1em;
          @include view-at(sp) {
            padding: 30px 10px 0;
            font-size: 10px;
            text-align: center;
          }
        }
      }
    }
  }

  &-news {
    &-wrap {
      @include view-at(sp) {
        overflow-x: scroll;
        margin-bottom: 20px;
      }
    }
    ul {
      margin-bottom: 40px;
      @include view-at(sp) {
        width: 900px;
        margin-bottom: 0;
      }
    }
    &-item {
      &-img {
        position: relative;

        span {
          position: absolute;
          top: 5px;
          left: 5px;
          background: $color-main;
          font-size: 12px;
          padding: 5px 15px;
          border-radius: 20px;
          color: white;
          line-height: 1;
        }
        
      }

      > a {
        font-size: 12px;
      }
    }
  }

  &-brand {
    &-item {
      &-wrap {
        background: $color-main;
        padding: 20px;
        @include view-at(sp) {
          padding: 10px;
          padding-bottom: 20px;
        }
      }
      &-text {
        padding: 30px 0;
        color: white;
        text-align: center;
        @include view-at(sp) {
          padding: 15px 0 20px;
        }

        &-ttl {
          letter-spacing: 0.2em;
          margin-bottom: 10px;
          @include view-at(sp) {
            margin-bottom: 5px;
            font-size: 18px;
          }
        }
        &-subttl {
          font-family: serif;
          font-size: 14px;
        }
      }
      &-img {
        padding-bottom: 20px;
        @include view-at(sp) {
          padding-bottom: 15px;
        }
      }
      &-btn {
        font-size: 12px;
        text-align: center;
        a {
          color: white;
          text-decoration: none;
        }
      }
    }
  }

  &-about {
    position: relative;
    background: $color-sub;
    margin-top: 180px;
    &-side {
      letter-spacing: 0.5em;
      writing-mode: vertical-rl;
      -ms-writing-mode: tb-rl;
      -webkit-writing-mode: vertical-rl;
      margin: 0 20px;
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      left: 20px;
      text-align: center;
      height: 100%;
      @include view-at(sp) {
        left: 10px;
        font-size: 10px;
        margin: 0;
      }
    }
    &-logo {
      position: absolute;
      top: -75px;
      left: 50%;
      transform: translateX(-50%);
      background: $color-sub;
      border-radius: 50%;
      width: 150px;
      @include view-at(sp) {
        width: 100px;
        top: -50px;
      }
    }
    &-contents {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      margin-top: 80px;
      padding-left: 80px;
      @include view-at(sp) {
        display: block;
        margin-left: 15px;
        margin-right: 15px;
        padding-left: 0;
      }

      &-left {
        width: 50%;
        margin-right: 5%;
        @include view-at(sp) {
          width: 100%;
          margin-right: 0;
          margin-bottom: 20px;
        }
      }
      &-right {
        width: 45%;
        @include view-at(sp) {
          width: 100%;
        }
      }
    }
  }

  &-contact {
    &-btn {
      display: flex;
      justify-content: center;
      margin: 40px 0;
      @include view-at(sp) {
        display: block;
      }
      a {
        border: 1px solid $color-gray-light;
        padding: 20px 50px;
        font-size: 14px;
        text-decoration: none;
        margin: 0 20px;
        letter-spacing: 0.2em;
        background: white;
        @include view-at(sp) {
          display: block;
          width: 98%;
          margin: 10px 1%;
          text-align: center;
        }
      }
    }
  }
}


.news-label {
  background: $color-main;
  font-size: 12px;
  padding: 5px 15px;
  border-radius: 20px;
  color: white;
  line-height: 1;
}
.out {
  position: relative;
  

  .top-brand-item-wrap {
    filter: brightness(0.5);
  }
  &::before {
    content: "coming soon";
    position: absolute;
    top: 50%;
    left: 50%; 
    transform: translate(-50%,-50%);
    color: white;
    z-index: +1;
    font-size: 20px;
    width: 90%;
    text-align: center;
  }
}
.mente {
  position: relative;
  

  .top-brand-item-wrap {
    filter: brightness(0.5);
  }
  &::before {
    content: "メンテナンス中";
    position: absolute;
    top: 50%;
    left: 50%; 
    transform: translate(-50%,-50%);
    color: white;
    z-index: +1;
    font-size: 20px;
    width: 90%;
    text-align: center;
  }
}
.nolink {
  a {
    pointer-events: none;
    background: #aaa;
  }
}