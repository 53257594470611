/* --------------------------
テキスト装飾
-------------------------- */
.u-text {
  font-size: 16px;
  line-height: 2em;
  @include view-at(sp) {
    font-size: 14px;
  }
}
.u-text-min {
  font-size: 14px;
  line-height: 2em;
  @include view-at(sp) {
    font-size: 12px;
  }
}

.u-text-accent {
  color: $color-blue-dark;
}

.u-text-important {
  color: $color-red;
}

.u-text-inverse {
  color: $color-white;
}

.u-text-sub {
  color: $color-gray;
}

.u-text-serif {
  font-family: serif;
}

.u-text-bold {
  font-weight: $bold;
}

.u-text-normal {
  font-weight: $normal;
}

.u-text-highlight {
  background: linear-gradient(transparent 70%, #f08080 70%);
}

.u-lineheight2 {
  line-height: 2em !important;
}

.u-text-x-large {
  font-size: nth($font-size, 1);
  letter-spacing: 0.06em;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 1);
  }
}

.u-text-large {
  font-size: nth($font-size, 2);
  line-height: nth($line-height, 2);
  letter-spacing: 0.05em;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 2);
    line-height: nth($line-height-sp, 2);
  }
}

.u-text-bit-large {
  font-size: nth($font-size, 3);
  line-height: nth($line-height, 3);

  @include view-at(sp) {
    font-size: nth($font-size-sp, 3);
    line-height: nth($line-height-sp, 3);
  }
}

.u-text-default {
  font-size: nth($font-size, 4);
  line-height: nth($line-height, 4);

  @include view-at(sp) {
    font-size: nth($font-size, 5);
    line-height: nth($line-height-sp, 4);
  }
}

.u-text-small {
  font-size: nth($font-size, 5);
  line-height: nth($line-height, 5);

  @include view-at(sp) {
    font-size: nth($font-size-sp, 5);
    line-height: nth($line-height-sp, 5);
  }
}

.u-text-x-small {
  font-size: nth($font-size, 6);
  line-height: nth($line-height, 6);

  @include view-at(sp) {
    font-size: 10px;
    line-height: nth($line-height-sp, 6);
  }

  &--sp {
    @include view-at(sp) {
      font-size: nth($font-size-sp, 6);
      line-height: nth($line-height-sp, 6);
    }
  }
}
