
.p-gnav {
  @include view-at(sp) {
    display: none;
    background-color: rgba($color: #000000, $alpha: 0.8);
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 9998;
  }

  &-inner {
    @include view-at(sp) {
      overflow-y: scroll;
      height: 100%;
      padding-bottom: 100px;
    }
  }

  &-list {
    display: flex;
    justify-content: flex-end;

    @include view-at(sp) {
      flex-wrap: wrap;
      justify-content: flex-start;
      text-align: center;
      margin: 20px;
      margin-top: 40px;
    }

    &-item {
      padding: 10px 25px;
      display: flex;
      justify-content: center;

      @include view-at(tab) {
        padding: 10px;
      }

      @include view-at(sp) {
        padding: 0;
        width: 100%;
        display: block;
        margin-bottom: 20px;
      }
      a {
        font-size: nth($font-size, 5);
        letter-spacing: 0.05em;
        color: $color-gray-dark;
        text-decoration: none;
        line-height: 1;
        font-weight: 400;

        @include view-at(sp) {
          padding: 10px;
          color: $color-white;
          display: block;
          letter-spacing: 0.2em;
          text-align: left;
          border-left: 5px solid;
        }
      }

      > ol {
        display: none;
        @include view-at(sp) {
          display: block;
          margin-top: 0;
        }
        li {
          a {
            padding: 10px;
            padding-left: 40px;
            border: none;
          }
        }
      }
    }

    &-second {
      border-bottom: 1px solid white;
      margin: 20px;
      padding-bottom: 20px;
      @include view-at(pc) {
        display: none;
      }
      ul {        
        li {
          padding: 10px 0;

          a {
            color: white;
            text-decoration: none;
            font-size: 12px;

            i {
              padding-right: 10px;
            }
          }
        }
      }
    }

    &-text {
      color: white;
      font-size: 12px;
    }

    &-bottom {
      margin: 20px;
      color: white;
      font-size: 12px;
      @include view-at(pc) {
        display: none;
      }

      a {
        color: black;
        font-size: 12px;
      }
    }

    &-sub {
      padding: 30px 20px 0;
      display: flex;
      flex-wrap: wrap;
      margin: -5px;
      @include view-at(pc) {
        display: none;
      }

      li {
        width: 50%;
        padding: 5px;
        text-align: center;
        a {
          color: white;
          border: 1px solid;
          padding: 20px 10px;
          display: block;
          font-size: 14px;
        }
        &.order {
          width: 100%;
          
          a {
            background: $color-main;
            border: none;
            color: white;
          }
        }
      }
    }

    &-page {
      margin: 20px;
      margin-top: 40px;
      @include view-at(pc) {
        display: none;
      }
      li {
        margin-bottom: 20px;
        a {
          color: white;
          font-size: 14px;

          span {
            font-size: 20px;
            padding-right: 10px;
            font-weight: bold;
          }
        }
      }
    }
  }
}
