.doll {

    &-flow {
        &-btn {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 20px;
            @include view-at(sp) {
                justify-content: center;
              }

            a {
                margin-right: 20px;
                width: 250px;
                letter-spacing: 0.1em;
                @include view-at(sp) {
                    margin-right: 0;
                }
                i {
                    padding-right: 10px;
                }
            }
        }
    }

    &-q {
        &-text {
            margin-bottom: 40px;
            .q {
                font-size: 16px;
                font-weight: bold;
                @include view-at(sp) {
                  font-size: 14px;
                }
            }

            p {
                margin-bottom: 10px;
                font-size: 14px;
                @include view-at(sp) {
                  font-size: 12px;
                }
            }
        }
    }

    &-maid {
        &-insta {
            max-width: 300px;
            margin: 0 auto;
        }
    }

    &-commitment {
        ol {
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            li {
                width: 48%;
                margin: 1%;
                @include view-at(sp) {
                  width: 100%;
                  margin: 5px 0;
                  font-size: 14px;
                }
                span {
                    background-color: #fff;
                    padding: 30px;
                    text-align: center;
                    position: relative;
                    z-index: 1;
                    display: block;
                    
                    &::before,
                    &::after {
                        content: "";
                        height: 100%;
                        position: absolute;
                        width: 100%;
                    }
                    &::before {
                        background-color: rgba(255, 255, 255, 1);
                        left: 0;
                        top: 0;
                        z-index: -1;
                        box-shadow: 0 1px 4px rgba(0, 0, 0, .3);
                    }
                    &::after {
                        top: 7px;
                        left: 7px;
                        z-index: -2;
                        content: '';
                        background-image: -webkit-repeating-linear-gradient(135deg, $color-gray-light, $color-gray-light 1px, transparent 2px, transparent 5px);
                        background-image: repeating-linear-gradient(-45deg, $color-gray-light, $color-gray-light 1px, transparent 2px, transparent 5px);
                    }
                }
            }
        }
    }

    .flow {
        padding-left: 120px;
        position: relative;
        @include view-at(sp) {
          padding: 0;
        }
      }
      .flow::before {
        content: "";
        width: 15px;
        height: 100%;
        background: #eee;
        margin-left: -8px;
        display: block;
        position: absolute;
        top: 0;
        left: 120px;
        @include view-at(sp) {
          content: none;
        }
      }
      .flow > li {
        position: relative;
      }
      .flow > li:not(:last-child) {
        margin-bottom: 8vh;
      }
      .flow > li .icon {
        font-size: 12px;
        color: #fff;
        background: #F49B83;
        // background: -moz-linear-gradient(left, #FCE53E 0%, #F48B4B 100%);
        // background: -webkit-linear-gradient(left, #FCE53E 0%,#F48B4B 100%);
        // background: linear-gradient(to right, #FCE53E 0%,#F48B4B 100%);
        // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FCE53E', endColorstr='#F48B4B',GradientType=1 );
        padding: 8px 20px;
        display: block;
        position: absolute;
        top: 0;
        left: -120px;
        z-index: 100;
        @include view-at(sp) {
          left: 0;
        }
      }
      .flow > li .icon::after {
        content: "";
        border-style: solid;
        border-width: 5px 0 5px 10px;
        border-color: transparent transparent transparent #F49B83;
        position: absolute;
        top: 50%;
        left: 100%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
      }
      .flow > li dl {
        padding-left: 70px;
        position: relative;
        @include view-at(sp) {
          padding: 0;
          padding-top: 5px;
        }
      }
      .flow > li dl::before,
      .flow > li dl::after {
        content: "";
        display: block;
        position: absolute;
        top: 15px;
        @include view-at(sp) {
          content: none;
        }
      }
      .flow > li dl::before {
        width: 7px;
        height: 7px;
        margin-top: -3px;
        background: #F49B83;
        border-radius: 50%;
        left: -4px;
      }
      .flow > li dl::after {
        width: 50px;
        border-bottom: 1px dashed #999;
        position: absolute;
        left: 5px;
      }
      .flow > li dl dt {
        font-size: 18px;
        font-weight: 600;
        color: #000;
        margin-bottom: 1vh;
        @include view-at(sp) {
          padding-left: 112px;
          margin-bottom: 20px;
          font-size: 18px;
        }
      }


      .top-contact-btn a {
        border: 1px solid $color-gray;
        padding: 20px 50px;
        font-size: 14px;
        text-decoration: none;
        margin: 0 20px;
        letter-spacing: 0.2em;
        position: relative;
        background: none;
        @include view-at(sp) {
          margin: 10px 0;
        }
        &::after {
          position: absolute;
          top: 5px;
          left: 5px;
          width: 100%;
          height: 100%;
          content: '';
          background-image: -webkit-repeating-linear-gradient( 135deg, #C2E4F0, #C2E4F0 1px, transparent 2px, transparent 5px);
          background-image: repeating-linear-gradient( -45deg, #C2E4F0, #C2E4F0 1px, transparent 2px, transparent 5px);
          background-size: 7px 7px;
          z-index: -1;
      }

      &.line {
        &::after {
          background-image: -webkit-repeating-linear-gradient( 135deg, #c2f0d8, #c2f0d8 1px, transparent 2px, transparent 5px);
          background-image: repeating-linear-gradient( -45deg, #c2f0d8, #c2f0d8 1px, transparent 2px, transparent 5px);
        }
      }

      i {
        padding-right: 5px;
      }
    }
}