.lp {
    &-mv {
        padding-top: 76px;
        @include view-at(sp) {
            padding-top: 60px;
        }
    }
    h2 {
        text-align: center;
        font-family: 'Zen Maru Gothic', sans-serif;
        font-size: 25px;
        @include view-at(sp) {
            font-size: 18px;
        }
        span {
            display: block;
            text-align: center;
            position: relative;
            padding-top: 10px;
            margin-top: 20px;
            font-size: 18px;
            color: #C87A7B;
            @include view-at(sp) {
                font-size: 14px;
                margin-top: 15px;
            }
            &::after {
                content: "";
                width: 250px;
                height: 1px;
                background: $color-gray;
                display: block;
                top: 0;
                left: 50%;
                position: absolute;
                transform: translateX(-50%);
            }
        }
    }

    &-cv {
        background: linear-gradient(180deg, transparent 0%, transparent 20%, #FBF8F9 20%, #FBF8F9 100%);
        margin-bottom: 20px;
    }

    .flow {
        padding-left: 120px;
        position: relative;
        @include view-at(sp) {
          padding: 0;
        }

        &::before {
            content: "";
            width: 15px;
            height: 100%;
            background: #eee;
            margin-left: -8px;
            display: block;
            position: absolute;
            top: 0;
            left: 120px;
            @include view-at(sp) {
            content: none;
            }
        }
        & > li {
            position: relative;
        }
        & > li:not(:last-child) {
            margin-bottom: 8vh;
            @include view-at(sp) {
                margin-bottom: 4vh;
            }
        }
    }
    
    .flow > li .icon {
    font-size: 12px;
    color: #fff;
    background: #C87A7B;
    padding: 8px 20px;
    display: block;
    position: absolute;
    top: 0;
    left: -120px;
    z-index: 100;
    @include view-at(sp) {
        left: 0;
    }
    }
    .flow > li .icon::after {
    content: "";
    border-style: solid;
    border-width: 5px 0 5px 10px;
    border-color: transparent transparent transparent #C87A7B;
    position: absolute;
    top: 50%;
    left: 100%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    }
    .flow > li dl {
    padding-left: 70px;
    position: relative;
    @include view-at(sp) {
        padding: 0;
        padding-top: 5px;
    }
    }
    .flow > li dl::before,
    .flow > li dl::after {
    content: "";
    display: block;
    position: absolute;
    top: 15px;
    @include view-at(sp) {
        content: none;
    }
    }
    .flow > li dl::before {
    width: 7px;
    height: 7px;
    margin-top: -3px;
    background: #C87A7B;
    border-radius: 50%;
    left: -4px;
    }
    .flow > li dl::after {
    width: 50px;
    border-bottom: 1px dashed #999;
    position: absolute;
    left: 5px;
    }
    .flow > li dl dt {
    font-size: 18px;
    font-weight: 600;
    color: #000;
    margin-bottom: 1vh;
    @include view-at(sp) {
        padding-left: 112px;
        margin-bottom: 20px;
        font-size: 18px;
    }
    }
}