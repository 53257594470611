.item-box {
    padding: 10px 0;
    border-bottom: 1px solid $color-gray;
    margin-bottom: 20px;
    h4 {
        font-weight: bold;
        font-size: 16px;
        padding-bottom: 10px;
        margin-bottom: 20px;
        color: orange;
        border-bottom: 1px solid $color-gray;
        position: relative;
        span {
            color: black;
            position: absolute;
            right: 0;
        }
    }
    &-inner {
        padding: 0 10px;
    }
    .table-size {
        margin-bottom: 20px;
        display: block;
        width: 100%;
        tbody {
            display: flex;
            flex-wrap: wrap;
            tr {
                width: 16.666666%;
                border: 1px solid darkgray;
                border-right: none;
                display: block;
                &:nth-last-child(1) {
                    border-right: 1px solid darkgray;
                }
                &:nth-child(6n) {
                    border-right: 1px solid darkgray;
                }
                th {
                    display: block;
                    background: beige;
                    padding: 5px;
                    text-align: center;
                    border-bottom: 1px solid darkgray;
                }
                td {

                }
            }
        }
    }
}

.item-list {
    li {
        padding: 10px;
        border-bottom: 1px solid $color-gray;
        position: relative;
        min-height: 120px;
        padding-bottom: 5px;
        padding-left: 120px;
        &.head {
            background: orange;
            color: white;
            border: none;
            display: flex;
            flex-wrap: wrap;
            min-height: auto;
            span {
                width: 70%;
                &:nth-child(1) {
                    width: 30%;
                }
            }
        }
        .img {
            position: absolute;
            left: 0;
            top: 10px;
            max-width: 100px;
        }
        .design {
            font-size: 80%;
        }
        .option {
            padding-top: 10px;
            font-size: 80%;
        }
        .option-01 {
            padding-right: 20px;
        }
        .option-02 {
            padding-right: 20px;
        }
    }
}