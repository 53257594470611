
.p-footer {
  border-top: 1px solid $color-gray-snow;
  padding: 20px 0 20px;
  color: $color-gray-dark;

  @include view-at(sp) {
    padding: 0 0 20px;
    margin-bottom: 80px;
  }

  &-logo {
    text-align: center;
    max-width: 200px;
    margin: 0 auto;

    @include view-at(sp) {
      margin: 0 auto;
    }
    &-min {
      max-width: 150px;
      margin: 0 auto;
    }
  }
  h4 {
    font-size: 16px;
    letter-spacing: 0.2em;
    margin-bottom: 10px;
    text-align: center;
  }
  &-brand {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    > li {
      padding: 5px;
      width: calc(100%/4);
      @include view-at(sp) {
        width: calc(100%/1);
      }

      > a {
        display: block;
        border: 1px solid $color-gray-light;
        padding: 20px 30px;
        text-decoration: none;
        text-align: center;
        font-size: 14px;
        @include view-at(sp) {
          padding: 15px;
        }
      }
    }
  }
  &-link {
    margin-bottom: 80px;
    ul {
      display: flex;
      justify-content: center;
      @include view-at(sp) {
        flex-wrap: wrap;
        margin: 20px 5px;
      }
      li {
        @include view-at(sp) {
          width: calc(100%/2);
          display: block;
          padding: 5px 0;
        }
        a {
          text-decoration: none;
          font-size: 13px;
          color: $color-gray-dark;
          padding-right: 20px;
          margin-right: 10px;
          position: relative;
          @include view-at(sp) {
            padding-right: 0;
          }
          &::after {
            content: "/";
            position: absolute;
            right: 0;
            font-size: 14px;
            top: 50%;
            transform: translateY(-50%);
            @include view-at(sp) {
              content: none;
            }
          }
          
        }
        &:nth-last-child(1) {
          a {
            padding-right: 0;
            &::after {
              content: none;
            }
          }
        }
      }
    }
  }
  &-copyright {
    font-size: 10px;
    text-align: center;
  }
  &-nav {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    @include view-at(sp) {
      flex-wrap: wrap;
      margin-bottom: 0;
      margin-top: 20px;
      justify-content: flex-start;
    }
    li {
      padding: 20px;
      @include view-at(sp) {
        padding: 10px 20px;
        width: 50%;
      }

      a {
        text-decoration: none;
      }
    }
  }
}
