.p-header {
  padding: 5px 0;
  width: 100%;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  background: white;

  &-inner {
    display: flex;
    justify-content: space-between;
    padding: 0 $contents-padding;
    @include view-at(sp) {
      justify-content: center;
    }
    h1 {
      font-size: 10px;
      margin-bottom: 10px;
      margin-top: 5px;
      text-align: right;
      font-family: serif;
      font-weight: 600;
      @include view-at(sp) {
        display: none;
      }
    }
  }

  &-logo {
    text-align: center;
    font-size: 0;

    a {
      display: inline-block;
      line-height: 1;
      font-size: 0;
      padding: 10px;
      @include view-at(sp) {
        padding: 5px;
      }

      img {
        max-width: 220px;
        @include view-at(sp) {
          max-width: 180px;
        }
      }
    }
  }
}
