/* --------------------------
パネル
-------------------------- */

.c-panel {
  background-color: #F49B83;
  color: white;
  padding: 20px;
  font-weight: bold;
  letter-spacing: 0.2em;

  &--border {
    border: 1px solid $color-gray-light;
    background-color: $color-white;
  }

  &--rounded {
    border-radius: 8px;
  }

  &-contents {
    padding: 20px;

    @include view-at(sp) {
      padding: 20px;
    }

    &--narrow {
      padding: 15px;
    }
  }
  &.gray {
    background-color: $color-gray-snow;
    color: #333;
  }
}
