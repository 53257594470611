/* --------------------------
セクション
-------------------------- */

.c-section {
  @include view-at(pc) {
    margin: nth($space, 1) 0;
  }

  @include view-at(sp) {
    margin: nth($space, 1) 0;
  }

  &--middle {
    @include view-at(pc) {
      margin: nth($space, 2) 0;
    }

    @include view-at(sp) {
      margin: nth($space-sp, 2) 0;
    }
  }

  &--narrow {
    @include view-at(pc) {
      margin: nth($space, 3) 0;
    }

    @include view-at(sp) {
      margin: nth($space-sp, 3) 0;
    }
  }

  &--bg {
    @include view-at(pc) {
      padding: nth($space, 1) 0;
    }

    @include view-at(sp) {
      padding: nth($space-sp, 1) 0;
    }

    &--middle {
      @include view-at(pc) {
        padding: nth($space, 2) 0;
      }
  
      @include view-at(sp) {
        padding: nth($space-sp, 2) 0;
      }
    }
  
    &--narrow {
      @include view-at(pc) {
        padding: nth($space, 3) 0;
      }
  
      @include view-at(sp) {
        padding: nth($space-sp, 3) 0;
      }
    }
  
  }


  &-container {
    max-width: $contents-width;
    margin: 0 auto;

    @include view-at(pc) {
      padding: 0 $contents-padding;
    }

    @include view-at(tab) {
      max-width: 100%;
      padding: 0 20px;
    }

    @include view-at(sp) {
      max-width: 100%;
      padding: 0 $contents-padding-sp;
    }

    &-inner {
      max-width: 760px;
      margin: auto;
    }
    &-wide {
      max-width: $contents-wide-width;
      margin: auto;
      padding: 0 $contents-padding;
      @include view-at(sp) {
        padding: 0 $contents-padding-sp;
      }
    }
  }
}
