/* --------------------------
フォーム
-------------------------- */

.c-form {
  @include view-at(sp) {
    display: block;
    width: 100%;
  }
  tbody {
    @include view-at(sp) {
      display: block;
      width: 100%;
    }
    tr {
      display: flex;
      margin-bottom: 40px;
      @include view-at(sp) {
        display: block;
      }
      th {
        padding-right: 40px;
        font-weight: bolder;
        width: 300px;
        @include view-at(sp) {
          display: block;
          width: 100%;
          padding: 20px;
          background: $color-gray-snow;
          margin-bottom: 20px;
          font-size: 12px;
        }
      }
      td {
        line-height: 1.8em;
        @include view-at(sp) {
          display: block;
          width: 100%;
          font-size: 14px;
        }
  
        input.text,
        textarea.text {
          width: 100%;
          padding: 10px;
        }
      }
    }
  }
  
  &-text {
    border: 1px solid $color-gray-light;
    width: 100%;
    padding: 3px 15px;
    border-radius: 4px;
    font-size: nth($font-size, 4);
    line-height: nth($line-height, 4);
  }
  &-textarea {
    border: 1px solid $color-gray-light;
    width: 100%;
    padding: 8px 5px;
    height: 100px;
    border-radius: 3px;
  }
  &-checkbox {
    &-input {
      display: none;

      &:checked + .c-form-checkbox-span::after{
        content: "";
        display: block;
        position: absolute;
        top: 5px;
        left: 4px;
        width: 8px;
        height: 12px;
        transform: rotate(40deg);
        border-bottom: 3px solid $color-red;
        border-right: 3px solid $color-red;
      }
    }

    &-span {
      padding-left: 28px;
      position:relative;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 5px;
        left: 0;
        width: 16px;
        height: 16px;
        border: 2px solid $color-gray;
        background-color: $color-white;
      }
    }
  }
  &-radio {
    &-input {
      display: none;

      &:checked + .c-form-radio-span::after{
        content: "";
        display: block;
        position: absolute;
        top: 9px;
        left: 4px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $color-red;
      }
    }

    &-span {
      padding-left: 28px;
      position:relative;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 5px;
        left: 0;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 2px solid $color-gray-light;
        background-color: $color-white;
      }
    }
  }
  &-number {
    width: 50px;
    padding: 5px;
    margin: 0 5px;
  }
  &-kids {
    label {
      font-size: 14px;
      padding-right: 20px;
      margin-bottom: 10px;
      display: inline-block;
      span::before {
        top: 2px;
      }
      .c-form-checkbox-input:checked + .c-form-checkbox-span::after {
        top: 2px;
        border-bottom: 3px solid orange;
        border-right: 3px solid orange;
      }
    }
  }
}

