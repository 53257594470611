/* --------------------------
テーブル
-------------------------- */

.c-table {
  width: 100%;
  font-size: nth($font-size, 4);
  line-height: nth($line-height, 4);

  thead {
    tr {
      margin-bottom: 20px;
      display: flex;
      flex-wrap: wrap;
      @include view-at(sp) {
        border-bottom: 0;
      }
    }
    th {
      padding: 25px;
      line-height: 1.5;
      font-weight: $bold;
      width: 30%;
      background-color: $color-gray-snow;
      vertical-align: top;
      display: block;

      @include view-at(sp) {
        display: block;
        width: 100%;
        text-align: center;
      }
    }
    td {
      font-weight: $bold;
      text-align: center;
      padding: 20px 30px;
      display: block;
      width: 70%;

      @include view-at(sp) {
        display: block;
        width: 100%;
      }
    }
  }

  tbody {
    tr {
      margin-bottom: 20px;
      display: flex;
      flex-wrap: wrap;
      @include view-at(sp) {
        display: block;
        border-bottom: 0;
      }
    }
    th {
      padding: 25px;
      line-height: 1.5;
      font-weight: $bold;
      width: 30%;
      background-color: $color-gray-snow;
      vertical-align: top;
      display: block;
      display: flex;
      align-items: center;

      @include view-at(sp) {
        display: block;
        width: 100%;
        text-align: left;
        padding: 15px;
        min-height: auto;
        font-size: 14px;
      }
    }
    td {
      padding: 20px 30px;
      display: block;
      width: 70%;

      @include view-at(sp) {
        display: block;
        width: 100%;
        padding: 15px 0;
      }
    }
  }

  &--narrow {
    border-color: $color-gray-light;
    tbody {
      tr {
        border-color: $color-gray-light;
      }
      th {
        padding: 8px 10px;
        line-height: 1.5;
        font-weight: normal;
        width: 120px;
        font-weight: $bold;
        text-align: right;

        @include view-at(sp) {
          padding: 8px;
          width: 80px;
        }
      }
      td {
        padding: 8px 10px 8px 20px;
        line-height: 1.5;

        @include view-at(sp) {
          padding: 8px 10px 8px 15px;
        }
      }
    }
  }
}
